.dashboard-section {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 #15223214;
}
.integration-section .platform-item,
.guideline-section .guideline-item,
.shortcut-section .shortcut-item,
.trending-product-section .trending-product-item,
.file-type-section .file-type-item,
.sync-order-platform-section .sync-order-platform-item,
.payment-method-section .payment-method-item{
  border-radius: 3px;
  border: solid 1px #e5e5e5;
  cursor: pointer;
  &:hover {
    border-color: #009aff;
  }
}
.payment-method-item-selected{
  border-color: #009aff !important;
}
.Rectangle-862 {
  width: 25px;
  height: 20px;
  margin: 5px 2px 5px 5px;
  padding: 3px 2px 3px 3px;
  border-radius: 3px;
  background-color: #465465;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sync-order-platform-section {
  & > div {
    width: 15%;
  }
}
.guideline-item {
  .guideline-item {
    p {
      color: #000000;
    }
  }
}
.steps-section {
  .step-item {
    border-radius: 5px;
    border: solid 1px #e5e5e5;
    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      border-radius: 12px;
      &::after {
        left: 33%;
      }
    }
    .ant-checkbox, .ant-checkbox-wrapper{
      cursor: unset;
    }
    .ant-checkbox-checked::after {
      border: none;
    }
    .ant-checkbox + span {
      margin-left: 8px;
    }
  }

  .ant-steps-item-container,
  .ant-steps-navigation .ant-steps-item::after {
    display: none;
  }
  .ant-steps-navigation .ant-steps-item::before {
    height: 3px;
    width: 100%;
  }
  .ant-steps-navigation .ant-steps-item-process::before {
    background-color: #e8e8e8;
  }
  .ant-steps-navigation .ant-steps-item::before {
    position: static;
  }
}
.trending-product-section .slick-prev:before,
.slick-next:before {
  color: #000000;
}
.tip-section {
  .ant-collapse-borderless {
    background-color: #fff;
  }
  .tip-custom-panel {
    padding: 0.5rem;
    border-radius: 5px;
    background-color: #f4f7fc;
    margin-bottom: 15px;
    .ant-collapse-header .ant-collapse-arrow {
      padding-top: 0.5rem;
      color: #1984ff;
    }
    .btn-outline-primary {
      padding: 6px 10px;
      border-radius: 4px;
    }
  }
}
.welcome-section {
  background-color: #fff;
}
.search-dashboard {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-img {
  fill: #465465;
}
.buttonAccept {
  height: 45px;
  padding: 14px 27px;
  border-radius: 5px;
  border: solid 1px #1984ff;
  background-color: #fff;
}
.Accept-all {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1984ff;
}
.ant-alert-no-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Skip-the-guide {
  color: #07f;
  text-decoration: underline;
  cursor: pointer;
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
}
