.fontSize-child {
  font-size: 14px;
}
.fontSize-parent {
  font-size: 16px;
}
.font-login {
  .font-login-title {
    font-size: 30px;
    font-weight: 600;
    margin-top: 32px;
  }
  .font-login-desc {
    font-size: 16px;
    color: #667085;
    margin-top: 12px;
  }
  .size-input {
    font-size: 14px;
    margin-top: 32px;
    max-width: 100%;
    .name-field {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #344054;
    }
    .forgot-password {
      color: #169dd8;
      font-weight: 600;
      font-size: 14px;
    }
    .login-button {
      color: white;
      font-weight: 600px;
      border: none;
      border-radius: 8px;
      width: 480px;
      height: 44px;
      margin-top: 24px;
      margin-bottom: 16px;
    }
    .devider-login {
      border-top: 1px solid #eaecf0;
      margin-bottom: 16px;
    }
  }
}

.input-field {
  border-color: #d0d5dd;
  border-radius: 8px;
}
.button-Login {
  height: 40px;
  border-radius: 3px;
}

.left-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 100%;
  padding-bottom: 111px;
  .reserved {
    position: absolute;
    bottom: 84px/2 - 7px;
    left: 0;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: $GRAY_600;
  }
}

.social-login-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  &:hover {
    background-color: transparent !important;
  }
  .button-Social {
    border-radius: 8px;
    border: solid 1px #d0d5dd;
    background-color: #fff;
    height: 44px;
    margin-bottom: 24px;
  }
  .social-login-icon {
    width: 24px;
    height: 24px;
  }
}
.footer-login {
  .text {
    font-weight: 400;
    color: #475467;
  }
  .sign-up {
    font-weight: 600;
    color: #169dd8;
  }
}
.form-login {
  .noti-margin {
    margin-top: 32px;
  }
}
