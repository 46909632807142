.left-sidebar-menu {
  &.ant-layout-sider-dark {
    // background-color: #2c3b41;
    .ant-menu {
      // background-color: #2c3b41;
    }

    // .ant-menu-item, .ant-menu-submenu-title {
    //   border-bottom: 1px solid #7c8e9d;
    // }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    margin-bottom: 0 !important;
    height: 53px !important;
    line-height: 55px !important;
    border-left: 3px solid transparent;
    margin: 0;
  }

  //   .ant-menu-item-selected {
  //     background-color: #f0f2f5 !important;
  //     border-left-color: #367fa9;//$primary;
  //     a{
  //       color: #000 !important;
  //     }
  //     // border-bottom-color: #728595 !important;
  // }
  .sidebar-menu-icon {
    width: 25px;
    text-align: center;
  }

  .ant-menu-inline-collapsed {
    // >.ant-menu-item{
    .sidebar-menu-icon + span {
      display: inline-block;
      max-width: 0;
      opacity: 0;
    }

    // }
  }
}

.custom-sidebar {
  #sidebar .ant-menu-inline-collapsed > .ant-menu-item,
  #sidebar
    .ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .left-sidebar-menu .ant-menu-inline-collapsed > .ant-menu-item,
  .left-sidebar-menu
    .ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title {
    padding: 0 22px !important;
  }
}

#sidebar,
.left-sidebar-menu {
  // &.ant-layout-sider-dark{
  //   background-color: rgb(100,117,131);
  //   .ant-menu{
  //     background-color: rgb(100,117,131);
  //   }
  //   .ant-menu-item {
  //     border-bottom: 1px solid rgb(124,142,157);
  //   }
  // }
  // .ant-menu-item {
  //   height: 60px;
  //   line-height: 55px;
  // }
  .sidebar-menu-icon {
    width: 25px;
    text-align: center;
  }

  .ant-menu-inline-collapsed {
    > .ant-menu-item,
    > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 25px !important;
    }

    .sidebar-menu-icon + span {
      display: inline-block;
      max-width: 0;
      opacity: 0;
    }
  }

  .ant-menu-submenu,
  .ant-menu-item {
    &,
    &-title {
      height: 50px !important;
      line-height: 50px !important;
      margin: 0;
    }

    &.ant-menu-submenu {
      height: auto !important;
    }

    &.primaryMenuItem {
      border-top: none;
    }

    a {
      color: $GRAY_500;
    }

    .sidebar-menu-icon {
      width: 32px;
      height: 32px;
      line-height: 32px;
      background-color: transparent;
      display: inline-block;
      text-align: center;
      border-radius: 4px;

      svg {
        stroke: $GRAY_500;
        margin-top: -2px;
        &.iconLog {
          fill: $GRAY_500;
          stroke: none;
        }
      }
    }

    .ant-menu-item-disabled {
      display: none;
    }

    &:hover,
    &-active,
    &-selected {
      background-color: #eff9fd;
      // width: 280px;
      // margin-left: 12px;
      // margin-right: 12px;

      span {
        font-weight: normal;
      }

      &::after {
        display: none;
      }

      .sidebar-menu-icon {
        stroke: $PRIMARY_500;
        svg {
          stroke: $PRIMARY_500;
          &.iconLog {
            fill: $PRIMARY_500;
            stroke: none;
          }
        }
      }

      > div span,
      > a span,
      > a span > span {
        color: $PRIMARY_500;
      }
    }

    .ant-menu-submenu-title {
      margin: 0;
    }
  }
}

.ant-tooltip-content {
  .sidebar-menu-icon {
    display: none;
  }
}

// .ant-menu-submenu-popup {
//   .ant-menu-sub {
//     // padding: 0 10px;
//     .ant-menu-item, .ant-menu-submenu{
//       height: 35px;
//       line-height: 35px;
//       background-color: transparent;
//       width: 200px;
//       padding-left: 30px;
//       // border-bottom: 1px solid rgb(124,142,157);
//       // padding-bottom: 0;
//       // margin-bottom: 0;
//       // margin-top: 0;
//     }
//   }
// }
