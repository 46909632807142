// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: "Roboto";
$font-size-base: 0.9rem;
$body-color: #212b48;
$line-height-base: 1.7;

$headings-font-weight: 700;
// $border-radius: 0;
// $border-radius-lg: 0;
// $border-radius-sm: 0;

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$custom-checkbox-indicator-border-radius: 0;

$custom-radio-indicator-border-radius: 0;

$custom-switch-indicator-border-radius: 0;

$custom-select-border-radius: 0;

$custom-range-track-border-radius: 0;

$custom-range-thumb-border-radius: 0;

$custom-file-border-radius: 0;

$nav-tabs-border-radius: 0;

$nav-pills-border-radius: 0;

$navbar-toggler-border-radius: 0;

$dropdown-border-radius: 0;
$dropdown-inner-border-radius: 0;

$card-border-radius: 0;
$card-inner-border-radius: 0;

$tooltip-border-radius: 0;

$form-feedback-tooltip-border-radius: 0;

$popover-border-radius: 0;
$popover-inner-border-radius: 0;

$toast-border-radius: 0;

$badge-border-radius: 0;

$badge-pill-border-radius: 0;

$modal-content-border-radius: 0;
$modal-content-inner-border-radius: 0;

$alert-border-radius: 0;

$progress-border-radius: 0;

$list-group-border-radius: 0;

$thumbnail-border-radius: 0;

$breadcrumb-border-radius: 0;

// Colors

$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #02c857;
$teal: #4dc0b5;
$cyan: #1dafdf;

$primary: #009aff;
$warning: #ec973b;
$nav-header-height: 69px;
$dark-mode: 100px;
$bg-header: #fff;
$layout-header-height: 60px;
$pro-header-hover-bg: rgba(0, 0, 0, 0.1);

$breakpoints: (
  'sx': 480px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1600px,
  '1024': 1024px,
  '1280': 1280px,
  '1360': 1360px,
  '1800': 1800px,
) !default;

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}
