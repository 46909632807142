.Rectangle-848 {
  background-color: #fff;
}
.Orders {
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.Rectangle-8 {
  width: 355px;
  height: 44px;
  margin: 0 15px 0 0;
  border-radius: 5px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}
.Rectangle-818 {
  width: 1619px;
  height: 148px;
  margin: 1px 0 110px;
  padding: 12.9px 962px 13px 20px;
  background-color: #fff8cc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Rectangle-817 {
  border-radius: 5px;
  background-color: #07f;
  width: 100%;
}
.Rectangle-btn {
  border-radius: 5px;
  background-color: #07f;
  width: 100%;
}
.exp-Btn {
  height: 45px;
  padding: 12.5px 17px 12.5px 12px;
  border-radius: 5px;
  border: #12af1c;
  background-color: #12af1c;
}
.Rectangle-830 {
  width: 1556px;
  height: 50px;
  margin: 0 0 16px;
  background-color: #fff;
}
.More-Filter {
  height: 19px;
  margin: 13px 0 12px 15px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #07f;
  cursor: pointer;
  text-decoration: underline;
}
.Filter {
  width: 46px;
  height: 24px;
  margin: 28px 34.3px 38px 0;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.Clear-all-filter {
  height: 45px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #07f;
}
.search-button {
  height: 45px;
  border-radius: 5px;
  background-color: #07f;
}
.header-filter {
  width: 55px;
  height: 19px;
  margin: 0 7.5px 10px 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.storeFront {
  width: 393px;
  height: 40px;
  margin: 12px 0 0;
  // padding: 1px 14px 11px 19px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
.colorTextOrder {
  color: #414141;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  // color: #363636 !important;
  font-weight: 500;
  padding: 20px;
  height: 46px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-tabs-tab {
  padding: 0;
}
.checkboxOrder {
  width: 355px;
  height: 60px;
  border-radius: 5px;
  background-color: #fff;
}
.actionButton {
  width: 104px;
  height: 40px;
  margin: 0 0 0 133px;
  // padding: 12px 14px 11px 16px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
.ant-checkbox-inner {
  width: 18px !important;
  height: 18px !important;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  &::after {
    left: 33%;
  }
}
.pagination {
  height: 60px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  background-color: #fff;
  align-items: center;
}
.ant-input-search-button {
  background-color: #fff;
  border-top: solid 1px #d9d9d9;
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
  border-left: none;
}
.ant-drawer-body {
  background-color: #fffcfc;
}
.tag-size {
  margin: 10px 10px 0 0;
  padding: 5.7px 15px 5.3px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: rgb(197, 232, 243);
  }
  &.chosen {
    background-color: rgb(55, 174, 230);
  }
}
.importOrderFile {
  .ant-upload.ant-upload-select {
    display: none;
  }
}
.custom-filter {
  .ant-drawer-header {
    background-color: #ebebeb;
    border-bottom-color: #e5e5e5;
  }
  .ant-drawer-body {
    padding: 0;
  }
}
.disable-link {
  cursor: default;
  opacity: 0.6;
}
