.PrimaryBtn {
  border-radius: 5px;
  background-color: #465465;
  color: #fff;
}
.View-more {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #07f;
}
