.headerSet {
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: normal;
}
.descripSet {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 10.2px 0 0;
}

.headerGen {
  margin-left: 12px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #0e1a29;
}

.desGen1 {
  margin: 0 0 0 15px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #0e1a29;
}
.desGen2 {
  margin: 12px 18px 0 15px;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
}
.backBtnGen {
  height: 45px;
  background-color: #465465;
  border-radius: 5px;
}
.saveBtnGen {
  height: 45px;

  border-radius: 5px;
  background-color: #07f;
}
.activeSwitch {
  // background-color: #12af1c;
}
.defaultShip {
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #0e1a29;
  margin: 21px 33px 10px 0;
}
.desShip {
  margin: 10px 283px 20px 0;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #0e1a29;
}
.typeSize {
  width: 100px;
  height: 17px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-top: 15px;
  margin-bottom: 10px;
}
.optionShip {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  color: #000;
}
.headerAcc {
  // padding: 5px 5px 5px 5px;
  margin: 15px 0px 15px 0px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.textInput {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: rgb(0, 0, 0);
}
.changeP {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: right;
  color: #07f;
  text-decoration: underline;
  cursor: pointer;
}
.connect-social {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: right;
  color: #07f;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.connect-header {
  margin: 15px 0 5px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.Personal-Information {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #0e1a29;
}
.deleteBtnGen {
  width: 191px;
  background-color: #465465;
  border-radius: 5px;
}
.desAcc {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #0e1a29;
}
.btnApi {
  width: 191px;
  border-radius: 5px;
}
