.main-header {
  background-color: $bg-header;
  .top-menu-toggler {
    margin-left: 1rem;
    font-size: 18px;
    line-height: $layout-header-height;
    cursor: pointer;
    transition: all 0.3s;
    // &:hover {
    //   background: $pro-header-hover-bg;
    // }
  }
}
