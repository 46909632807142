$BASE_WHITE: #FFFFFF;
$BASE_BLACK: #000000;
$GRAY_25: #FCFCFD;
$GRAY_50: #F9FAFB;
$GRAY_100: #F2F4F7;
$GRAY_200: #EAECF0;
$GRAY_300: #D0D5DD;
$GRAY_400: #98A2B3;
$GRAY_500: #667085;
$GRAY_600: #475467;
$GRAY_700: #344054;
$GRAY_800: #1D2939;
$GRAY_900: #101828;
$PRIMARY_25: #F5F9FD;
$PRIMARY_50: #EFF9FD;
$PRIMARY_100: #C1E7F8;
$PRIMARY_200: #93D6F3;
$PRIMARY_300: #64C5EF;
$PRIMARY_400: #36B3EA;
$PRIMARY_500: #169DD8;
$PRIMARY_600: #117BA9;
$PRIMARY_700: #0C597B;
$PRIMARY_800: #07384D;
$PRIMARY_900: #03161E;
$ERROR_25: #FFFBFA;
$ERROR_50: #FEF3F2;
$ERROR_100: #FEE4E2;
$ERROR_200: #FECDCA;
$ERROR_300: #FDA29B;
$ERROR_400: #F97066;
$ERROR_500: #F04438;
$ERROR_600: #D92D20;
$ERROR_700: #B42318;
$ERROR_800: #912018;
$ERROR_900: #7A271A;
$WARNING_25: #FFFCF5;
$WARNING_50: #FFFAEB;
$WARNING_100: #FEF0C7;
$WARNING_200: #FEDF89;
$WARNING_300: #FEC84B;
$WARNING_400: #FDB022;
$WARNING_500: #F79009;
$WARNING_600: #DC6803;
$WARNING_700: #B54708;
$WARNING_800: #93370D;
$WARNING_900: #7A2E0E;
$SUCCESS_25: #F6FEF9;
$SUCCESS_50: #ECFDF3;
$SUCCESS_100: #D1FADF;
$SUCCESS_200: #A6F4C5;
$SUCCESS_300: #6CE9A6;
$SUCCESS_400: #32D583;
$SUCCESS_500: #12B76A;
$SUCCESS_600: #039855;
$SUCCESS_700: #027A48;
$SUCCESS_800: #05603A;
$SUCCESS_900: #054F31;
$GRAY_BLUE_25: #FCFCFD;
$BROWN_500: #8A5D3C;
$GRAY_BLUE_50: #F8F9FC;
$GRAY_BLUE_100: #EAECF5;
$GRAY_BLUE_200: #D5D9EB;
$GRAY_BLUE_300: #B3B8DB;
$GRAY_BLUE_400: #717BBC;
$GRAY_BLUE_500: #4E5BA6;
$GRAY_BLUE_600: #3E4784;
$GRAY_BLUE_700: #363F72;
$GRAY_BLUE_800: #293056;
$GRAY_BLUE_900: #101323;
$GRAY_COOL_25: #FCFCFD;
$GRAY_COOL_50: #F9F9FB;
$GRAY_COOL_100: #EFF1F5;
$GRAY_COOL_200: #DCDFEA;
$GRAY_COOL_300: #B9C0D4;
$GRAY_COOL_400: #7D89B0;
$GRAY_COOL_500: #5D6B98;
$GRAY_COOL_600: #4A5578;
$GRAY_COOL_700: #404968;
$GRAY_COOL_800: #30374F;
$GRAY_COOL_900: #111322;
$GRAY_MODERN_25: #FCFCFD;
$GRAY_MODERN_50: #F8FAFC;
$GRAY_MODERN_100: #EEF2F6;
$GRAY_MODERN_200: #E3E8EF;
$GRAY_MODERN_300: #CDD5DF;
$GRAY_MODERN_400: #9AA4B2;
$GRAY_MODERN_500: #697586;
$GRAY_MODERN_600: #4B5565;
$GRAY_MODERN_700: #364152;
$GRAY_MODERN_800: #202939;
$GRAY_MODERN_900: #121926;
$GRAY_NEUTRAL_25: #FCFCFD;
$GRAY_NEUTRAL_50: #F9FAFB;
$GRAY_NEUTRAL_100: #F3F4F6;
$GRAY_NEUTRAL_200: #E5E7EB;
$GRAY_NEUTRAL_300: #D2D6DB;
$GRAY_NEUTRAL_400: #9DA4AE;
$GRAY_NEUTRAL_500: #6C737F;
$GRAY_NEUTRAL_600: #4D5761;
$GRAY_NEUTRAL_700: #384250;
$GRAY_NEUTRAL_800: #1F2A37;
$GRAY_NEUTRAL_900: #111927;
$GRAY_IRON_25: #FCFCFC;
$GRAY_IRON_50: #FAFAFA;
$GRAY_IRON_100: #F4F4F5;
$GRAY_IRON_200: #E4E4E7;
$GRAY_IRON_300: #D1D1D6;
$GRAY_IRON_400: #A0A0AB;
$GRAY_IRON_500: #70707B;
$GRAY_IRON_600: #51525C;
$GRAY_IRON_700: #3F3F46;
$GRAY_IRON_800: #26272B;
$GRAY_IRON_900: #18181B;
$GRAY_TRUE_25: #FCFCFC;
$GRAY_TRUE_50: #FAFAFA;
$GRAY_TRUE_100: #F5F5F5;
$GRAY_TRUE_200: #E5E5E5;
$GRAY_TRUE_300: #D6D6D6;
$GRAY_TRUE_400: #A3A3A3;
$GRAY_TRUE_500: #737373;
$GRAY_TRUE_600: #525252;
$GRAY_TRUE_700: #424242;
$GRAY_TRUE_800: #292929;
$GRAY_TRUE_900: #141414;
$GRAY_WARM_25: #FDFDFC;
$GRAY_WARM_50: #FAFAF9;
$GRAY_WARM_100: #F5F5F4;
$GRAY_WARM_200: #E7E5E4;
$GRAY_WARM_300: #D7D3D0;
$GRAY_WARM_400: #A9A29D;
$GRAY_WARM_500: #79716B;
$GRAY_WARM_600: #57534E;
$GRAY_WARM_700: #44403C;
$GRAY_WARM_800: #292524;
$GRAY_WARM_900: #1C1917;
$MOSS_25: #FAFDF7;
$MOSS_50: #F5FBEE;
$MOSS_100: #E6F4D7;
$MOSS_200: #CEEAB0;
$MOSS_300: #ACDC79;
$MOSS_400: #86CB3C;
$MOSS_500: #669F2A;
$MOSS_600: #4F7A21;
$MOSS_700: #3F621A;
$MOSS_800: #335015;
$MOSS_900: #2B4212;
$GREEN_LIGHT_25: #FAFEF5;
$GREEN_LIGHT_50: #F3FEE7;
$GREEN_LIGHT_100: #E4FBCC;
$GREEN_LIGHT_200: #D0F8AB;
$GREEN_LIGHT_300: #A6EF67;
$GREEN_LIGHT_400: #85E13A;
$GREEN_LIGHT_500: #66C61C;
$GREEN_LIGHT_600: #4CA30D;
$GREEN_LIGHT_700: #3B7C0F;
$GREEN_LIGHT_800: #326212;
$GREEN_LIGHT_900: #2B5314;
$GREEN_25: #F6FEF9;
$GREEN_50: #EDFCF2;
$GREEN_100: #D3F8DF;
$GREEN_200: #AAF0C4;
$GREEN_300: #73E2A3;
$GREEN_400: #3CCB7F;
$GREEN_500: #16B364;
$GREEN_600: #099250;
$GREEN_700: #087443;
$GREEN_800: #095C37;
$GREEN_900: #084C2E;
$TEAL_25: #F6FEFC;
$TEAL_50: #F0FDF9;
$TEAL_100: #CCFBEF;
$TEAL_200: #99F6E0;
$TEAL_300: #5FE9D0;
$TEAL_400: #2ED3B7;
$TEAL_500: #15B79E;
$TEAL_600: #0E9384;
$TEAL_700: #107569;
$TEAL_800: #125D56;
$TEAL_900: #134E48;
$CYAN_25: #F5FEFF;
$CYAN_50: #ECFDFF;
$CYAN_100: #CFF9FE;
$CYAN_200: #A5F0FC;
$CYAN_300: #67E3F9;
$CYAN_400: #22CCEE;
$CYAN_500: #06AED4;
$CYAN_600: #088AB2;
$CYAN_700: #0E7090;
$CYAN_800: #155B75;
$CYAN_900: #164C63;
$BLUE_LIGHT_25: #F5FBFF;
$BLUE_LIGHT_50: #F0F9FF;
$BLUE_LIGHT_100: #E0F2FE;
$BLUE_LIGHT_200: #B9E6FE;
$BLUE_LIGHT_300: #7CD4FD;
$BLUE_LIGHT_400: #36BFFA;
$BLUE_LIGHT_500: #0BA5EC;
$BLUE_LIGHT_600: #0086C9;
$BLUE_LIGHT_700: #026AA2;
$BLUE_LIGHT_800: #065986;
$BLUE_LIGHT_900: #0B4A6F;
$BLUE_25: #F5FAFF;
$BLUE_50: #EFF8FF;
$BLUE_100: #D1E9FF;
$BLUE_200: #B2DDFF;
$BLUE_300: #84CAFF;
$BLUE_400: #53B1FD;
$BLUE_500: #2E90FA;
$BLUE_600: #1570EF;
$BLUE_700: #175CD3;
$BLUE_800: #1849A9;
$BLUE_900: #194185;
$BLUE_DARK_25: #F5F8FF;
$BLUE_DARK_50: #EFF4FF;
$BLUE_DARK_100: #D1E0FF;
$BLUE_DARK_200: #B2CCFF;
$BLUE_DARK_300: #84ADFF;
$BLUE_DARK_400: #528BFF;
$BLUE_DARK_500: #2970FF;
$BLUE_DARK_600: #155EEF;
$BLUE_DARK_700: #004EEB;
$BLUE_DARK_800: #0040C1;
$BLUE_DARK_900: #00359E;
$INDIGO_25: #F5F8FF;
$INDIGO_50: #EEF4FF;
$INDIGO_100: #E0EAFF;
$INDIGO_200: #C7D7FE;
$INDIGO_300: #A4BCFD;
$INDIGO_400: #8098F9;
$INDIGO_500: #6172F3;
$INDIGO_600: #444CE7;
$INDIGO_700: #3538CD;
$INDIGO_800: #2D31A6;
$INDIGO_900: #2D3282;
$VIOLET_25: #FBFAFF;
$VIOLET_50: #F5F3FF;
$VIOLET_100: #ECE9FE;
$VIOLET_200: #DDD6FE;
$VIOLET_300: #C3B5FD;
$VIOLET_400: #A48AFB;
$VIOLET_500: #875BF7;
$VIOLET_600: #7839EE;
$VIOLET_700: #6927DA;
$VIOLET_800: #5720B7;
$VIOLET_900: #491C96;
$PURPLE_25: #FAFAFF;
$PURPLE_50: #F4F3FF;
$PURPLE_100: #EBE9FE;
$PURPLE_200: #D9D6FE;
$PURPLE_300: #BDB4FE;
$PURPLE_400: #9B8AFB;
$PURPLE_500: #7A5AF8;
$PURPLE_600: #6938EF;
$PURPLE_700: #5925DC;
$PURPLE_800: #4A1FB8;
$PURPLE_900: #3E1C96;
$FUCHSIA_25: #FEFAFF;
$FUCHSIA_50: #FDF4FF;
$FUCHSIA_100: #FBE8FF;
$FUCHSIA_200: #F6D0FE;
$FUCHSIA_300: #EEAAFD;
$FUCHSIA_400: #E478FA;
$FUCHSIA_500: #D444F1;
$FUCHSIA_600: #BA24D5;
$FUCHSIA_700: #9F1AB1;
$FUCHSIA_800: #821890;
$FUCHSIA_900: #6F1877;
$PINK_25: #FEF6FB;
$PINK_50: #FDF2FA;
$PINK_100: #FCE7F6;
$PINK_200: #FCCEEE;
$PINK_300: #FAA7E0;
$PINK_400: #F670C7;
$PINK_500: #EE46BC;
$PINK_600: #DD2590;
$PINK_700: #C11574;
$PINK_800: #9E165F;
$PINK_900: #851651;
$ROSE_25: #FFF5F6;
$ROSE_50: #FFF1F3;
$ROSE_100: #FFE4E8;
$ROSE_200: #FECDD6;
$ROSE_300: #FEA3B4;
$ROSE_400: #FD6F8E;
$ROSE_500: #F63D68;
$ROSE_600: #E31B54;
$ROSE_700: #C01048;
$ROSE_800: #A11043;
$ROSE_900: #89123E;
$ORANGE_DARK_25: #FFF9F5;
$ORANGE_DARK_50: #FFF4ED;
$ORANGE_DARK_100: #FFE6D5;
$ORANGE_DARK_200: #FFD6AE;
$ORANGE_DARK_300: #FF9C66;
$ORANGE_DARK_400: #FF692E;
$ORANGE_DARK_500: #FF4405;
$ORANGE_DARK_600: #E62E05;
$ORANGE_DARK_700: #BC1B06;
$ORANGE_DARK_800: #97180C;
$ORANGE_DARK_900: #771A0D;
$ORANGE_25: #FEFAF5;
$ORANGE_50: #FEF6EE;
$ORANGE_100: #FDEAD7;
$ORANGE_200: #F9DBAF;
$ORANGE_300: #F7B27A;
$ORANGE_400: #F38744;
$ORANGE_500: #EF6820;
$ORANGE_600: #E04F16;
$ORANGE_700: #B93815;
$ORANGE_800: #932F19;
$ORANGE_900: #772917;
$YELLOW_25: #FEFDF0;
$YELLOW_50: #FEFBE8;
$YELLOW_100: #FEF7C3;
$YELLOW_200: #FEEE95;
$YELLOW_300: #FDE272;
$YELLOW_400: #FAC515;
$YELLOW_500: #EAAA08;
$YELLOW_600: #CA8504;
$YELLOW_700: #A15C07;
$YELLOW_800: #854A0E;
$YELLOW_900: #713B12;