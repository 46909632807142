.general-infomation{
    background-color: #212b48;
    padding: 20px 0;
    .number{
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 0;
        line-height: 48px;
    }
  
}