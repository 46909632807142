.site-header {
  height: auto;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;

  .btn-bell {
    padding: 25px 20px;
    margin: 0 15px;
    &.ant-dropdown-open {
      background: #ccc;
    }
  }
  .btn-toggle-menu {
    padding: 20px 10px;
    line-height: 0;
    margin: 0 15px;
    color: #919eab;
    font-size: 30px;
    &:focus {
      box-shadow: none;
    }
  }
  .site-header-nav {
    .nav-link {
      color: #282828;
      transition: all 200ms ease;
      &:hover,
      &:active,
      &:focus,
      &.active {
        color: $primary;
      }
      &.active {
        font-weight: bold;
      }
    }
  }
}
.notification-item:hover {
  .notification-title {
    font-weight: bold;
  }
}
.btn-bell {
  color: #919eab;
  &:focus {
    box-shadow: none;
  }
  &.ant-dropdown-open {
    box-shadow: none;
    color: #212b48;
  }
}
.menu-dropdown .nav-link {
  color: #282828;
  transition: all 200ms ease;
  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $primary;
  }
  &.active {
    font-weight: bold;
  }
}
