.main-logo {
  height: $nav-header-height;
  position: relative;
  line-height: $nav-header-height;
  transition: all 0.3s;
  overflow: hidden;
  color: #fff;
  text-align: center;
  img {
    display: inline-block;
    vertical-align: middle;
    // height: 32px;
  }
  h1 {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin: 0 0 0 12px;
    font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 600;
  }
}
