.cardReviewCampaig {
  margin: 20px 20px 20px 21px;
  padding: 22.2px 26.1px 30px 44px;
  background-color: #fff;
}
.positionTable {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-top: 10px;
}
.border-preview {
  border-bottom: solid 1px #e5e5e5;
}

