@import 'site-header';
@import 'sidebar-menu';
@import 'general-infomation';
@import 'logo';
@import 'header';
@import 'left_sidebar';
@import 'dashboard';
@import 'order';
@import 'campaign';
@import 'login';
@import 'billing';
@import 'setting';

body,
* {
  font: 'Inter';
  font-family: 'Inter';
  font-weight: 500;
}
.clearfix {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}
iframe[style='position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;'] {
  display: none;
}
a:hover {
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer;
}

.user-select-none {
  user-select: none;
}

.border-dashed {
  border-style: dashed !important;
}

.btn-warning {
  color: #fff;
}

.bg-success-light {
  background-color: #d9ffe9;
}

.ant-input,
.ant-select-selection,
.ant-input-number-input,
.ant-input-number,
.ant-select-search__field {
  border-radius: 0 !important;
  border-color: #c4cdd5 !important;
}

.ant-modal-header,
.ant-modal-content {
  border-radius: 0;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-radius: 0;
}
.create-campaign,
.create-mockup {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    margin: 0 auto;
    height: auto !important;
    padding: 10px 20px;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: block;
  }
}

.site-badge {
  .ant-badge-count {
    height: 25px;
    line-height: 25px;
    border-radius: 12.5px;
  }

  &.site-badge-secondary .ant-badge-count {
    border: solid 1px #d4d6d8;
    background-color: #fcfcfc;
    color: #848484;
  }

  &.site-badge-success .ant-badge-count {
    border: solid 1px #02c857;
    background-color: #d9ffe9;
    color: #02c857;
  }
}

.ant-steps-dot .ant-steps-item-content {
  width: 90%;
}

.input-number-lg-icon {
  width: 40px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin-nested-loading > div > .ant-spin {
  position: fixed;
}

// .anticon svg{
//     margin-top: -8px;
// }
.vertical-line {
  border-left: 1px solid #fff;
  height: 100%;
  position: absolute;
  left: calc(50% - 1px);
  top: 0;
  z-index: 19;
}

.react-resizable {
  max-width: 100%;
}

.invalid-feedback,
.valid-feedback {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f04438;
  display: block;
}

.font-size-12 {
  font-size: 12px !important;
}

.border-active {
  border-color: $primary !important;
  border: 1px solid #169dd8;
  /* Shadow/xl */

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
}

.border-hover {
  border: 1px solid #eaecf0;
  transition: all 200ms ease;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  &:hover {
    cursor: pointer;
    border-color: $primary !important;
    border: 1px solid #169dd8;
    /* Shadow/xl */
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 16px;
  }
}

.border-transparent-hover {
  border: 1px solid transparent;
  transition: all 200ms ease;

  &:hover {
    cursor: pointer;
    border-color: $primary !important;
  }
}

.border-dotted-hover {
  border: 1px solid #ccc;
  transition: all 200ms ease;

  &:hover {
    cursor: pointer;
    border-color: $primary !important;
  }
}

.box-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
}

.upload-w-100 {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    margin: 0 auto;
  }
}

.hide-empty {
  .ant-empty-normal {
    display: none;
  }
}

.ant-modal-close,
.ant-modal-close-x,
button:focus {
  outline: none;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-20 {
  font-size: 20px !important;
}
.image-design-upload {
  .ant-upload.ant-upload-select-picture-card {
    height: auto;
    border: none;
  }
}
.edit-barcode-file .ant-upload.ant-upload-select-picture-card {
  width: auto;
}

.notic2 {
  background-color: rgb(212, 245, 226);
}
.button-Login {
  border-radius: 3px;
  padding: 10px 15px;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  border: none;
  cursor: pointer;
  transition: 0.3s linear;
}

$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #9b9b9b;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  padding-bottom: 0px;
  padding-top: 16px;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 15px;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: $gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    color: $primary;
    font-size: 15px;
    font-weight: 700;
  }
  padding-bottom: 0px;
  padding-top: 16px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}
.input.form__field {
  background-color: red !important;
}
.shadow-auth {
  border: rgb(207, 243, 252) solid 1px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  margin: 100px auto;
  background: white;
  border-radius: 5px;
}
.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: rgb(0, 0, 0);
  font-size: 30px;
  font-weight: bold;
  align-items: center;
  position: relative;
  font-family: 'OCR A Std, monospace';
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}
.box-message {
  position: relative;
  box-shadow: 0px 0px 5px #333;
  background-position: left center;
  width: 100%;
  height: calc(50vw * 0.75);
  margin: 0 auto;
  overflow: hidden;
}

.box_logo {
  -ms-transform: translate(-50%, 5%);
  transform: translate(-50%, 5%);
  left: 50%;
  top: 1%;
  margin: 0;
}
.box_text {
  -ms-transform: translate(-50%, 10%);
  transform: translate(-50%, 0%);
  left: 50%;
  top: 60%;
  margin: 0;
  width: 90%;
  font-size: calc(3vw / 2.1);
  word-break: break-word;
}
.box_logo,
.box_text {
  position: absolute;
}
.box_logo img {
  width: auto;
  height: calc(40vw / 2);
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700;
}
.order-new-tracking {
  background-color: #ffa520;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;
}
.ant-radio-wrapper {
  white-space: break-spaces;
}

.custom-header-sticky {
  .ant-table-sticky-header {
    top: 62px !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
  }
}

.dark-divider.ant-divider-horizontal.ant-divider-with-text::before,
.dark-divider.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #e5e5e5;
}

.right-authentication-section {
  background-color: #F5F9FD;
  padding: 0;
  margin: 0;
}
.social-login:hover {
  box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.4);
}
button.fb-login {
  background-color: #3b5998;
}
button.google-login {
  background-color: #db4437;
}

.bg-danger-custom,
.ant-table-tbody > tr.ant-table-row-selected.bg-danger-custom > td {
  background-color: $ORANGE_100 !important;
}

.ant-table-tbody > tr.ant-table-row:hover.bg-danger-custom > td {
  background-color: $ORANGE_200 !important;
}
.bg-success-custom,
.ant-table-tbody > tr.ant-table-row-selected.bg-success-custom > td {
  background-color: $GREEN_LIGHT_50 !important;
}

.ant-table-tbody > tr.ant-table-row:hover.bg-success-custom > td {
  background-color: $GREEN_LIGHT_100 !important;
}


.ant-table-tbody > tr.ant-table-row.bg-odd-row > td {
  background-color: #f1f1f1 !important;
}
.max-width-300 {
  max-width: 300px !important;
}
.footer-logo {
  width: 30%;
}
.custom-vertical-align-antd-table .ant-table-cell {
  vertical-align: baseline !important;
}
.mt-1rem {
  margin-top: 1rem !important;
}
.mt-1dot5rem {
  margin-top: 1.5rem !important;
}
.mt-1dot7rem {
  margin-top: 1.7rem !important;
}
.ant-back-top-content {
  background-color: #1890ff;
}
.main-content {
  height: 100% !important;
}
.issue-table {
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0 !important;
    .ant-table-thead {
      display: none !important;
    }
  }
  .ant-table-tbody > tr > td {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-table-row-expand-icon-expanded {
    display: none;
  }
}
.ratingOrder {
  .star-ratings {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
.h-50px {
  height: 50px !important;
}
.h-50px {
  // background-image: url(/static/media/BannerLogin.d905de92.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: contain;
}
.authentication-slider-item {
  p {
    color: #0e1a29;
    font-size: 16px;
  }

  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-position: 50% 50%;
  background-color: #0e75af;
  // background-size: auto 100%;
  background-size: contain;
  background-repeat: no-repeat, repeat-x;
  img {
    width: 50%;
    margin: 0 auto;
    display: block;
    &.logo {
      width: 30%;
      margin-top: 60px;
    }
    &.text {
      width: 60%;
      margin-top: 48px;
    }
  }
}
.authentication-slider {
  @include respond-to('md') {
    position: sticky;
    top: 0;
    right: 0;
  }
}

.profile-section {
  width: 40%;
  margin: 0 auto;
}
.update-information {
  .ant-input-group-addon {
    font-size: 17px;
  }
  .anticon {
    display: flex;
    align-items: center;
  }
}
.btn-create-campaign {
  width: 166px;
  height: 45px;
  margin: 13px 36.3px 12px 25px;
  padding: 12.5px 20px 12.5px 17px;
  border-radius: 5px;
  border: solid 1px #12af1c;
  background-color: #fff;
  &:hover {
    background-color: #dff5e0;
  }
}
.startOver {
  border-radius: 5px;
  background-color: #465465;
}
.createCampaign {
  border-radius: 5px;
  background-color: #0077ff;
}
.icon-menu {
  margin: 0 0 30px;
  padding: 0 24.5px 0 34px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
}
.primaryMenuItem {
  border-top: solid 0.5px #f2f2f2;
  &::after {
    border-right: 0px !important;
  }
}
.lastItem {
  border-bottom: solid 0.5px #f2f2f2;
}
.nonPrimaryMenuItem {
  border-top: transparent;
  &::after {
    left: 10% !important;
    right: auto !important;
  }
}
.like-button {
  position: absolute;
  top: 5px;
  right: 10px;
}
.account-infor {
  .ant-space-item {
    line-height: 20px;
  }
}
.nonPrimaryMenuItem.ant-menu-submenu-selected > ul {
  display: block !important;
}
.hidden-popup-menu {
  display: none !important;
}
.ant-layout-sider-light,
.custom-sidebar > div,
.custom-sidebar {
  .ant-layout-sider {
    height: 100% !important;
  }
  .ant-layout-sider-trigger {
    top: 80px;
    width: 28px !important;
    height: 28px !important;
    border-radius: 25%;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
  .anticon-right {
    line-height: 23px;
    display: block;
    svg {
      color: black;
      font-weight: 700;
    }
  }
  .anticon-left {
    line-height: 23px;
    display: block;
    svg {
      color: black;
      font-weight: 700;
    }
  }
}
.custom-sidebar .d-none-menu * {
  display: none !important;
}
.d-none-menu .main-logo,
.d-none-menu .main-logo .img,
.d-none-menu .ant-layout-sider {
  display: block !important;
}
.main-menu .ant-menu-vertical,
.main-menu > div > div > div {
  height: 100%;
}
.g-wallet-card {
  background-color: #dbdbdb;
}
.payoneer-wallet-card {
  background-color: #f4f7fc;
}
.paypal-wallet-card {
  border: 1px solid #dbdbdb;
}
.billing-infor-item {
  border: 1px solid #e5e5e5;
}
.billing-infor-item-selected {
  border-color: #b0f8ff;
  background-color: rgb(230, 249, 255);
}
.custom_fc_frame {
  height: 20px !important;
  width: 40px !important;
}
.h-open-container {
  height: 520px !important;
  width: 366px !important;
}
.fc_open {
  height: 520px !important;
}
.ant-comment-content-author-name {
  color: #333 !important;
}
.ant-comment-content-detail p {
  white-space: normal !important;
}
.disable-resizebox + .react-resizable-handle {
  display: none !important;
}

.ant-modal-content {
  max-width: 100%;
}

@media all and (max-width: 1600px) {
  .ant-layout-sider .ant-layout-sider-trigger {
    left: 3.75% !important;
  }
}
@media all and (max-width: 1400px) {
  .ant-layout-sider .ant-layout-sider-trigger {
    left: 4.15% !important;
  }
}
@media all and (max-width: 1200px) {
  .ant-layout-sider .ant-layout-sider-trigger {
    left: 5.15% !important;
  }
}
@media all and (max-width: 992px) {
  .profile-section {
    width: auto;
  }
}
@media all and (max-width: 767px) {
  .custom-sidebar .ant-affix {
    height: auto !important;
  }
}
@media all and (max-width: 480px) {
  .nonPrimaryMenuItem.ant-menu-item-only-child {
    padding-left: 60px !important;
  }
  .nonPrimaryMenuItem.ant-menu-submenu-inline {
    padding-left: 12px !important;
  }
  .ant-pagination-total-text {
    display: none;
  }
  .ant-pagination.ant-table-pagination {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-alert-no-icon {
    display: block;
  }
  .footer-logo {
    width: 50%;
  }
  .btnApi {
    width: 100%;
  }
  .custom-header-sticky {
    .ant-table-sticky-header {
      top: 64px !important;
    }
  }
  .header {
    font-weight: 600;
  }
}

.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  display: none;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -1px;
}

.ant-input::placeholder {
  color: rgb(117, 117, 117);
  font-size: 15px;
  font-weight: 500;
}
.form-control::placeholder {
  color: rgb(117, 117, 117);
  font-size: 15px;
  font-weight: 500;
}

